<template>
  <div class="box">
    <div class="header">
      <div class="sun-box" title="积云教育">
        <div id="sun_jiaoyu"></div>
      </div>
      <div class="intro-box">
        <p class="title">欢迎来到</p>
        <p class="title">积云教育</p>
        <p class="intro">
          现代项目的开发，需要掌握多种技术。互联网项目，需
          要用到后端开发、前端开发、 界面设计、产品设计、数据库、各种
          移动客户端、三屏兼容、 restFul API设计和OAuth等等
        </p>
        <div class="line"></div>
      </div>
      <router-link to="/jiyun_solar">
        <img class="btn-delete" src="../../../src/assets/usian/delete.png" />
      </router-link>
    </div>
    <Video_masks ref="video"></Video_masks>
    <div class="footer">
      <div class="img-box" @click="$refs.video.checkVideoFun(videoInfo.image)">
        <img class="btn-play" src="../../../src/assets/usian/btn-play.png" />
      </div>
    </div>
  </div>
</template>

<script>
import * as THREE from "three/build/three.min.js";
import sun_1024 from "../../../src/assets/usian/sun_1024.jpg";
import Video_masks from "../../components/video_masks/video_masks";
const axios = require("axios").default;

let rendererJiaoyu = null;

export default {
  name: "jiyunjiaoyu",
  components: { Video_masks },
  data() {
    return {
      videoInfo: {}
    };
  },
  mounted() {
    rendererJiaoyu = new THREE.WebGLRenderer({ antialias: true, alpha: true }); //渲染器
    this.init();
    this.getVideoInfo();
  },
  beforeDestroy() {
    if (rendererJiaoyu) {
      rendererJiaoyu.forceContextLoss();
      rendererJiaoyu.domElement = null;
      rendererJiaoyu = null;
    }
  },
  methods: {
    clickVideo(src) {
      this.$refs.video.checkVideoFun(src);
    },
    init() {
      this.createStar({
        type: "sun_jiaoyu",
        width: this.getSize("sun_jiaoyu").width,
        size: [this.getSize("sun_jiaoyu").width / 2, 24, 24],
        img: sun_1024
      });
    },
    //积云影音
    getVideoInfo() {
      axios
        .get("/api/block/getItemsByName?name=积云影音&limit=1")
        .then(({ data: { data = [] } }) => {
          this.videoInfo = data.length > 0 ? data[0] : {};
        });
    },
    createStar(params) {
      //场景
      var scene = new THREE.Scene();

      //灯光
      //点光源
      var pointLight = new THREE.PointLight(0xffffff);
      pointLight.position.set(10, 0, 10);
      scene.add(pointLight);

      //太阳光
      let directionalLight = new THREE.DirectionalLight(0xffffff, 2.5);
      directionalLight.position.set(0, 0, 10).normalize();
      scene.add(directionalLight);

      //摄像
      var camera = new THREE.PerspectiveCamera(60, 1, 1, 1000);
      camera.position.set(params.width * 0.72, 0, params.width * 0.72); //设置相机位置
      camera.lookAt(scene.position); //设置相机方向(指向的场景对象)

      //演员
      var geometry = new THREE.SphereBufferGeometry(
        params.size[0],
        params.size[1],
        params.size[2]
      );
      var material = new THREE.MeshPhongMaterial({
        color: 0xffffff
      });
      var textureLoader = new THREE.TextureLoader();
      textureLoader.load(params.img, function(tex) {
        material.map = tex;
        material.map.encoding = THREE.sRGBEncoding;
        material.needsUpdate = true;
      });
      var mesh = new THREE.Mesh(geometry, material);
      scene.add(mesh);

      //action
      rendererJiaoyu.setClearAlpha(0);
      rendererJiaoyu.setSize(params.width, params.width);
      document
        .getElementById(params.type)
        .appendChild(rendererJiaoyu.domElement);
      render();

      function render() {
        if (rendererJiaoyu) {
          rendererJiaoyu.render(scene, camera);
          mesh.rotateY(0.001);
          requestAnimationFrame(render);
        }
      }
    },
    getSize(domStr) {
      var width = document.getElementById(domStr).offsetWidth;
      var height = document.getElementById(domStr).offsetHeight;
      return { width, height };
    }
  }
};
</script>

<style lang="scss" scoped>
.box {
  margin: 0;
  background: url("../../../src/assets/usian/bg-intro.jpg") center no-repeat;
  background-size: cover;
  position: relative;
  overflow: auto;
  height: 100vh;
}

.sun-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* 积云教育 */
#sun_jiaoyu {
  width: 500px;
  height: 500px;
}

.header {
  display: flex;
  justify-content: center;
  padding: 50px 0;
}

.intro-box {
  width: 330px;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
}

.title {
  font-size: 46px;
  margin: 5px 0;
  text-align: left;
}

.intro {
  font-size: 16px;
  line-height: 1.5;
  color: #a0a0a0;
  text-align: left;
}

.footer {
  display: flex;
  justify-content: center;
}

.footer > .img-box {
  cursor: pointer;
  width: 62.5vw;
  height: 20.83vw;
  background: url(../../../src/assets/usian/video.jpg) center no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-play {
  width: 118px;
  height: 118px;
}

.btn-delete {
  width: 24px;
  height: 24px;
  position: fixed;
  right: 10.42vw;
  top: 11.11vh;
}

.line {
  width: 74px;
  height: 1px;
  background-color: #fff;
  margin-top: 30px;
}
</style>
